// export const BASE_URL_PUBLIC = 'https://dev-api.gogtas.com/support/public/api'
// export const BASE_URL = 'https://dev-api.gogtas.com/support/api'
// export const API_VERSION = 'v1'

export const ADMIN_URL = 'https://dev-api.gogtas.com/support/admin/api'


export const API_PATHS = {
  login: 'login',
  company: 'company',
  user: 'user',
  ticket: 'ticket',
  dashboard: 'dashboard',
  template: 'template',
  category: 'category',
  article: 'article',
  folder: 'folder',
  organisationType: 'organisation-type',
  department: 'department',
  departmenttype: 'department-type',
  updateOnlineStatus: 'updateOnlineStatus',
  forgot: 'forgot',
  password: 'password',
  service: 'service',
  api: 'api',
  zoom: 'zoom'
}

export const USER_ROLE = {
  SUPPER_ADMIN: '1',
  ORG_ADMIN: '2',
  AGENT: '3',
  USER: '4',
  ESME: '5'
};


export const TICKET_PRIORITY = {
  URGENT: '1',
  HIGH: '2',
  MEDIUM: '3',
  LOW: '4'

}

export const FILE_TYPE = {
  USER_LOGO: "1",
  ORG_LOGO: "2",
  TICKET_ATTACHMENT: "3",
  INSTRUCTION_IMAGE: "4",
  INSTRUCTION_VIDEO: "5",
  ESME_RESUME: "6",
  ESME_DOCUMENT: "7"
}
export const TICKET_STATUS = {
  OPEN: "1",
  PENDING: "2",
  CLOSED: "3",
  WAITING_ON_CUSTOMER: "4",
  WAITING_ON_THIRD_PARTY: "5"
}

export const TEMPLATE_ORG_TYPE = {
  All: "1",
  School: "2",
  Police: "3",
  Medical: "4",
  IT: "5"
}

export const projectCode = {
  projectCode: "Semilynx01"
}

export const InstructionMediaType = {
  Image: "1",
  Video: "2",
  Text: "3"
}



