// ** Store Imports
import React, { useState } from 'react'
import { store } from 'src/store'
import { Provider } from 'react-redux'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'

// ** Config Imports
import 'src/configs/i18n'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// import NotificationToast from './NotificationToast';
import 'react-toastify/dist/ReactToastify.css'

// ** Component Imports
import ThemeComponent from 'src/@core/theme/ThemeComponent'

import WindowWrapper from 'src/@core/components/window-wrapper'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Toast Popup css
import 'react-toastify/dist/ReactToastify.css'

//import antd css
import 'antd/dist/antd.css'

import Routes from 'src/routes'

// ** Global css styles
import 'src/styles/globals.css'

import TicketState from 'src/context/TicketContext'
import AdminState from 'src/context/AdminContext'
import ChatState from 'src/context/ChatContext'
import OrganisationState from 'src/context/OrganisationContext'
import KnowledgeState from 'src/context/KnowledgeContext'

import { useEffect } from 'react'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

import { initializeApp } from 'firebase/app'

import { useLocation, useNavigate } from 'react-router-dom'

// toast popup
import { Slide, toast, ToastContainer } from 'react-toastify'

// import { useNavigate } from 'react-router'

const clientSideEmotionCache = createEmotionCache()

// ** Configure JSS & ClassName
const App = () => {
  const [userToken, setUserToken] = useState('')
  const [adminToken, setAdminToken] = useState('')

  useEffect(() => {

    const admin = JSON.parse(localStorage.getItem('userData') || '{}')
    const user = JSON.parse(localStorage.getItem('user1Data') || '{}')
    setAdminToken(admin?.token)
    setUserToken(user?.token)
  }, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const firebaseMessagingInit = async () => {

    if (!('Notification' in window)) {
        console.log("Browser doesn't support Notification");
        toast.error("Browser doesn't support notifications.", {
          transition: Slide,
      
          
      });
    } else if (Notification.permission === 'granted') {
        generateFCMToken();
    } else if (Notification.permission !== 'denied') {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                generateFCMToken();
            } else {
                console.log('Notification request was not granted by the user.');
                
                toast.warning('Please enable notifications in your browser settings to receive updates.', {
                  transition: Slide,
                  autoClose:false,
                  className:"w-[430px] left-[-60px]"
              });
                observePermissionChange(); 
            }
        } catch (error) {
            console.log('Error during notification permission request:', error);
        }
    } else {
        console.log('Notification permission has been denied by the user.');
        toast.warning('Please enable notifications in your browser settings to receive updates.', {
          transition: Slide,
          autoClose:false,
          className:"w-[430px] left-[-60px]"
      });
    
        observePermissionChange(); 
    }
};
const observePermissionChange = () => {
    let permission = Notification.permission;
    const intervalId = setInterval(() => {
        if (Notification.permission !== permission) {
            permission = Notification.permission;

            if (permission === 'granted') {
                console.log('Permission granted after change.');
                generateFCMToken();
                clearInterval(intervalId); 
            }
        }
    }, 1000); 
};


  const getConfig = () => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAmBN0N1GOC2ySr8vl44MKRGO3OK1cbzvw',
      authDomain: 'semilynx-dfb24.firebaseapp.com',
      databaseURL: 'https://semilynx-dfb24.firebaseio.com',
      projectId: 'semilynx-dfb24',
      storageBucket: 'semilynx-dfb24.appspot.com',
      messagingSenderId: '258329116393',
      appId: '1:258329116393:web:b53ca4de72df278045ba78',
      measurementId: 'G-ZKWZX4TNKR'
    }

    return initializeApp(firebaseConfig)
  }

  const generateFCMToken = async () => {
    const messaging = getMessaging(getConfig())
    console.log('Generating token...')

    const token = await getToken(messaging, {
      vapidKey: 'BNZcM4sdzkWxhKO-OgsE2j_oP8tsjYFDicjeYgPKj1pAIMTff3CARG6dw_rSY1wwvchFWSRqpz5Gxh0ngO_S5jI'
    })
    console.log('Generated')

    window.localStorage.setItem('firebaseToken', token)

    //setFirebaseToken(token)

    // console.log('GENERATED TOKEN', token)

    // subscribeTokenToTopic(token,'foo-bar')
    fcmForeGroundMessageListen(messaging)
  }

  /* function subscribeTokenToTopic(token:any, topic:any) {
    fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key=AAAAPCWdfuk:APA91bH0vA5gGaewcLh5pMmSD29Zil1lqCov6p83_xeVrVpoAjQLKyZOu5CoaKiRuJqbI3bbOFCI77U815C6ry1ftV95SLgFoy6A8b1gPXYghCl1YIQV7l_qwG78wW-YtqCwWl_UuNxW'
      })
    }).then(response => {
      if (response.status < 200 || response.status >= 400) {
        throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
      }
      console.log('Subscribed to "'+topic+'"');
    }).catch(error => {
      console.error(error);
    })
  } */

  /*   useEffect(()=>{
      const user = JSON.parse(localStorage.getItem('user1Data') || '{}')
      const userId = user?.data?.userId
      setUserId(userId)
    },[]) */

  const fcmForeGroundMessageListen = (messaging: any) => {

    //https://us06web.zoom.us/s/86790121152?zak=eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6IkJpZzA3UFJKVG5pcmZHek9iR3d0dlEiLCJpc3MiOiJ3ZWIiLCJzayI6IjAiLCJzdHkiOjk5LCJ3Y2QiOiJ1czA2IiwiY2x0IjowLCJtbnVtIjoiODY3OTAxMjExNTIiLCJleHAiOjE2OTY0MDM0NjMsImlhdCI6MTY4ODYyNzQ2MywiYWlkIjoiSTkzOTBvNnBSQ3FIeFU2cEpTOUFsUSIsImNpZCI6IiJ9.dkybxUqFiPV8F3ZbF9M5-G5ZwC0wvEk9_syebI3DtUk
    onMessage(messaging, payload => {
      console.log('Notification received in app onmessage:', payload); 

      toast.info(
        <a target='_blank' rel='noreferrer' href={payload.data?.url}>
          <span style={{ color: '#000', fontSize: '14px' }}>{payload.notification?.body}</span>
        </a>,
        { autoClose: 300000 }
      )

      //setNewItem(payload?.notification?.body)
    })
  }

  useEffect(() => {
    firebaseMessagingInit()
  }, [])

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const path = pathname
  const url = path

  // Extract task ID and token from the URL
  const parts = url.split('/') // Split the URL by '/'
  const pathName = parts[1] // The task ID is the third part
  const taskId = parts[2] // The task ID is the third part
  const token = parts[3] // The token is the fourth part

  useEffect(() => {
    if (pathName == 'work_instructions' && taskId && token) {
      console.log(taskId + '===' + token)
    }
  }, [taskId])

  useEffect(() => {
    if (
      userToken == undefined &&
      adminToken == undefined &&
      pathName !== 'work_instructions' &&
      pathName !== 'demo_wi' &&
      pathName !== 'signup' &&
      pathName !== 'terms-and-condition' &&
      pathName !== 'userlogin'
    ) {
      navigate('../loginWith')
      console.log('in app')
    }
  }, [userToken])

  return (
    <>
      <Provider store={store}>
        <AdminState>
          <KnowledgeState>
            <OrganisationState>
              <TicketState>
                <ChatState>
                  <CacheProvider value={clientSideEmotionCache}>
                    <SettingsProvider>
                      <SettingsConsumer>
                        {({ settings }) => {
                          return (
                            <ThemeComponent settings={settings}>
                              <WindowWrapper>
                                <Routes />

                                {/* {/ <ToastContainer /> /} */}
                              </WindowWrapper>
                              <ReactHotToast>
                                <Toaster
                                  position={settings.toastPosition}
                                  toastOptions={{ className: 'react-hot-toast' }}
                                />
                              </ReactHotToast>
                            </ThemeComponent>
                          )
                        }}
                      </SettingsConsumer>
                    </SettingsProvider>
                  </CacheProvider>
                </ChatState>
              </TicketState>
            </OrganisationState>
          </KnowledgeState>
        </AdminState>
      </Provider>
    </>
  )
}

export default App
