import * as React from 'react'
import { useState } from 'react'
import TicketContext from './TicketProvider'

const AdminState = (props: any) => {
  const defaultStyle = {
    width: '100%',
    position: 'absolute',
    top: '0px',
    right: '-100%',
    zIndex: '2',
    transition: '0.5s linear',
    height: '100vh',
    overflowY: 'hidden'
  }
  const [opacity, setOpacity] = useState<any>('none')

  const [createUserAgentList, setCreateUserAgentList] = useState(false)
  const [createOrgList, setCreateOrgtList] = useState(false)
  const [pendingTicketsStyle, setPendingTicketsStyle] = useState<any>(defaultStyle)

  
  const handleCloseBtn = () => {
    setOpacity('none')
  }
  const handleViewBtn = () => {
    setOpacity('block')
  }
  
  //ticket listing slider open
  const handleAdminSliderOpen = () => {
    handleCloseBtn()
    setPendingTicketsStyle({
      ...defaultStyle,
      right: '0'
    })

  }

  const handleAdminSliderClose = () => {
    setPendingTicketsStyle(defaultStyle)
  }


  return (
    <>
      <TicketContext.Provider
        value={{
          createUserAgentList,
          setCreateUserAgentList,
          setCreateOrgtList,
          createOrgList,
          handleAdminSliderClose,
          handleAdminSliderOpen,
          handleViewBtn,
          opacity,
          pendingTicketsStyle
        }}
      >
        {props.children}
      </TicketContext.Provider>
    </>
  )
}

export default AdminState
