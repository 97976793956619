import FirebaseContext from './FirebaseProvider'
import { useState } from 'react'

const FirebaseState = (props: any) => {
  
    const [getfirebaseToken, setFirebaseToken] = useState(0)
    const [OnlineStatusArrayByFB, setOnlineStatusArrayByFB] = useState([])


  return (
    <>
      <FirebaseContext.Provider
        value={{
            getfirebaseToken, 
            setFirebaseToken,
            OnlineStatusArrayByFB, 
            setOnlineStatusArrayByFB
        }}
      >
        {props.children}
      </FirebaseContext.Provider>
    </>
  )
}

export default FirebaseState
