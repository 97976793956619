import * as React from 'react'
import { useState } from 'react'
import ChatContext from './ChatProvider'

const defaultStyle = {
  width: '100%',
  position: 'absolute',
  top: '0px',
  right: '-100%',
  zIndex: '2',
  transition: '0.5s linear',
  height: '100vh',
  overflowY: 'hidden'
}

const ChatState = (props: any) => {
  const [showActiveSupport, setShowActiveSupport] = useState(false)
  const [userLogin, setuserLogin] = useState()
  const [getUserImg, setGetUserImg] = useState<any>()
  const [showChat, setShowChat] = useState(false)
  const [updateUserStyle, setupdateUserStyle] = useState<any>(defaultStyle)

  // for focusing in user/dashboard -> useriSME/ and eSME

  const [focusitem, setFocusItem] = useState(false)

  const handleUserUpdate = () => {
    setupdateUserStyle({
      ...defaultStyle,
      right: '0'
    })
  }

  const handleUserUpdateClose = () => {
    console.log('closeoffence')
    setupdateUserStyle(defaultStyle)
  }

  return (
    <>
      <ChatContext.Provider
        value={{
          showActiveSupport,
          setShowActiveSupport,
          setuserLogin,
          userLogin,
          setGetUserImg,
          getUserImg,
          setShowChat,
          showChat,
          updateUserStyle,
          handleUserUpdate,
          handleUserUpdateClose,
          focusitem,
          setFocusItem
        }}
      >
        {props.children}
      </ChatContext.Provider>
    </>
  )
}

export default ChatState
