import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './pages/App'
import FirebaseState from 'src/context/FirebaseContext'

// import 'src/styles/globals.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <FirebaseState>
      <App />
      </FirebaseState>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
