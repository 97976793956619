import * as React from 'react'
import { useState } from 'react'
import KnowledgeContext from './knowledgeProvider'

const OrganisationState = (props: any) => {
  const [categoryopen, setCategoryopen] = useState(false)
  const [createtemplatelist, setCreateTemplateList] = useState(false)
  const [firstmodalopen, setfirstmodalOpen] = useState<boolean>(false)
  const [categoryIdforAddArticle, setcategoryIdforAddArticle] = useState(0)
  const [folderIdaddarticle, setfolderIdaddarticle] = useState(0)
  const [addTagaddarticle, setaddTagaddarticle] = useState('')
  const [addDescriptionTemplateaddarticle, setaddDescriptionTemplateaddarticle] = useState('')
  const [applyTemplateID, setApplyTemplateID] = useState(0)
  const [selectedTemplateId, setSelectedTemplateId] = useState(0)
  const [previewArticleId, setPreviewArticleId] = React.useState(0)
  const [draftArticleCatId, setDraftArticleCatId] = React.useState(0)
  const [draftArticleFolderId, setDraftArticleFolderId] = React.useState(0)
  const [draftArticleaddTag, setDraftArticleaddTag] = useState('')
  const [createCategory, setCreateCategory] = useState<any>()
  const [categoryIdaddarticle, setCategoryIdaddarticle] = useState(0)
  const [refreshAddArticleform, setRefreshAddArticleform] = useState(false)
  const [refreshsidebarright, setRefreshsidebarright] = useState(false)
  const [getDraft, setgetdraft] = useState<any>();
  const [folderId, setFolderId] = useState()
  const [categoryId, setCategoryId] = useState()
  const [folderAddcatId, setFolderAddcatId] = useState()
  const [folderEditDialogue, setFolderEditDialogue] = useState(false)
  const [catId, setCatId] = useState<any>(1)

  //used catId direction path
  const [catIdDirection, setCatIdDirection] = useState<any>()


  //state uised for edit folder
  const [editFolderName, setEditFolderName] = useState('')
  const [editFolderDescription, setEditFolderDescription] = useState("")
  const [editFolderId, seteditFolderId] = useState();
  const [editFolderCatId, seteditFolderCatId] = useState();


  //used state for admin
  const [addFolder, setAddFolder] = useState<any>()


  return (
    <>
      <KnowledgeContext.Provider
        value={{
          categoryopen,
          setCategoryopen,
          createtemplatelist,
          setCreateTemplateList,
          firstmodalopen,
          setfirstmodalOpen,
          categoryIdforAddArticle,
          setcategoryIdforAddArticle,
          folderIdaddarticle,
          setfolderIdaddarticle,
          addTagaddarticle,
          setaddTagaddarticle,
          addDescriptionTemplateaddarticle,
          setaddDescriptionTemplateaddarticle,
          applyTemplateID,
          setApplyTemplateID,
          selectedTemplateId,
          setSelectedTemplateId,
          setAddFolder,
          addFolder,
          previewArticleId,
          setPreviewArticleId,
          draftArticleCatId,
          setDraftArticleCatId,
          draftArticleFolderId,
          setDraftArticleFolderId,
          draftArticleaddTag,
          setDraftArticleaddTag,
          setCreateCategory,
          createCategory,
          categoryIdaddarticle,
          setCategoryIdaddarticle,
          refreshAddArticleform,
          setRefreshAddArticleform,
          refreshsidebarright,
          setRefreshsidebarright,
          setgetdraft,
          getDraft,
          setFolderId,
          folderId,
          setCatId,
          catId,
          setCategoryId,
          categoryId,
          setFolderAddcatId,
          folderAddcatId,
          setFolderEditDialogue,
          folderEditDialogue,
          setEditFolderName,
          editFolderName,
          setEditFolderDescription,
          editFolderDescription,
          seteditFolderId,
          editFolderId,
          seteditFolderCatId,
          editFolderCatId,
          setCatIdDirection,
          catIdDirection,
        }}
      >
        {props.children}
      </KnowledgeContext.Provider>
    </>
  )
}

export default OrganisationState
